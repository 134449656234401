import { useRef, useState } from "react";

import useLogout from "../hooks/useLogout";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import constants from "../constants";


export default function Home() {
    const keywordRef = useRef("");
    const limitRef = useRef(5);
    const [searching, setSearching] = useState(false);
    const [listings, setListings] = useState([]);
    const logout = useLogout();
    const axiosPrivate = useAxiosPrivate();

    const search = async () => {
        setSearching(true);
        const response = await axiosPrivate.get(
            constants.BACKEND_URI_SEARCH,
            {
                params: {
                    keywords: keywordRef.current.value,
                    limit: limitRef.current.value
                },
                headers: {
                    'Content-Type' : 'application/json', 
                    'Access-Control-Allow-Origin': process.env.REACT_APP_BACKEND_URL
                },
                withCredentials: true,
            },
        );
        setListings(response.data);
        keywordRef.current.value = "";
        setSearching(false);
    }

    const signOut = async () => {
        await logout();
        window.location.href = process.env.REACT_APP_FRONTEND_URL;
    }

    return (
        <div>
            <div>
                <label>Keywords</label>
                <input ref={keywordRef} placeholder="keywords"/>
                <label>Limit</label>
                <input ref={limitRef} placeholder="limit" defaultValue={5}/>
                <button onClick={search} disabled={searching}>Search</button>
                <button onClick={signOut}>Sign Out</button>
            </div>
            <div>
                <table>
                    <tr>
                        <th>Listings</th>
                    </tr>
                    {listings.length && listings.map((listing, i) => 
                        <tr key={i}>
                            <td><a href={listing.viewItemURL} target="_blank">{listing.title}</a></td>
                        </tr>
                    )}
                </table>
            </div>
        </div>
    )
}
