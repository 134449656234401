import constants from "../constants";

export default function Marketing() {
    const login = () => {
        window.location.href = constants.EBAY_AUTH_URL;
    }

    return (
        <button onClick={login}>Login</button>
    );
}
