import { Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import Home from './components/Home';
import Marketing from './components/Marketing';
import Missing from './components/Missing';

function App() {
    return (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<RequireAuth />}>
                <Route path="home" element={<Home />} />
            </Route>
            <Route path="" element={<Marketing />} />
            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
      );
}

export default App;
