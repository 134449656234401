import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';


const RequireAuth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    if (searchParams.has('token')) {
        localStorage.setItem('token', searchParams.get('token'));
    }

    useEffect(async () => {
        let isMounted = true;

        if (searchParams.has('newWorkspace')) {
            searchParams.delete('newWorkspace');
            setSearchParams(searchParams);
        }

        if (searchParams.has('token')) {
            searchParams.delete('token');
            setSearchParams(searchParams);
        }

        isMounted && setIsLoading(false);

        return () => isMounted = false;
    }, [])

    if (isLoading) {
        return (
            <div className='loading-page'>
                <div>
                    <p>
                        <strong>Loading</strong>
                    </p>
                </div>
            </div>
        );
    } else if (localStorage.getItem('token')) {
        return (<Outlet />);
    } else {
        window.location.href = process.env.REACT_APP_FRONTEND_URL;
    }
}

export default RequireAuth;