import { React } from 'react'
import { Transition } from '@headlessui/react'
import useAuth from '../hooks/useAuth';


const ToastNotification = () => {
    const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } = useAuth();

    const handleSuccessAfterEnter = () => {
        setTimeout(() => {
          setSuccessMessage(null);
        }, 1000);
    }

    const handleErrorAfterEnter = () => {
        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
    }

    return (
        <div>
            <Transition
                show={successMessage != null}
                enter="transition duration-300 ease-in-out"
                enterFrom="opacity-0 -translate-y-24"
                enterTo="opacity-100 translate-y-0"
                leave="transition duration-500"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-24"
                afterEnter={handleSuccessAfterEnter}
            >
                <div id="toast" className="toast-success flex items-center w-full max-w-s p-4 mb-4 rounded-lg shadow" role="alert">
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-white rounded-lg">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Check icon</span>
                    </div>
                    <div className="ml-3 text-sm font-normal">{successMessage}</div>
                    <button type="button" className="ml-auto -mx-1.5 -my-1.5 text-gray-900 hover:text-black rounded-lg p-1.5 inline-flex h-8 w-8" data-dismiss-target="#toast-success" onClick={() => {setSuccessMessage(null)}} aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
            </Transition>
            <Transition
                show={errorMessage != null}
                enter="transition duration-300 ease-in-out"
                enterFrom="opacity-0 -translate-y-24"
                enterTo="opacity-100 translate-y-0"
                leave="transition duration-500"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-24"
                afterEnter={handleErrorAfterEnter}
            >
                <div id="toast" className="toast-failure flex items-center w-full max-w-s p-4 mb-4 rounded-lg shadow" role="alert">
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-[#D15046] bg-white rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        <span className="sr-only">X icon</span>
                    </div>
                    <div className="ml-3 text-sm font-normal">{errorMessage}</div>
                    <button type="button" className="ml-auto -mx-1.5 -my-1.5 text-gray-900 hover:text-black rounded-lg p-1.5 inline-flex h-8 w-8" data-dismiss-target="#toast-success" onClick={() => {setErrorMessage(null)}} aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
            </Transition>
        </div>
    )
}

export default ToastNotification;