import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "./useAuth";
import constants from "../constants";

const useLogout = () => {
    const { setAccessToken } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const logout = async () => {
        setAccessToken(null);
        localStorage.setItem('token', null);
        try {
            await axiosPrivate.post(constants.BACKEND_URI_LOGOUT, {});
        } catch (err) {
            console.error(err);
        }
    }
    return logout;
}

export default useLogout