import { Outlet } from "react-router-dom"
import ToastNotification from "./ToastNotification"



const Layout = () => {

    return (
        <main className="App h-full">
            <Outlet />
            <div className="toast-container">
                <ToastNotification />
            </div>
        </main>
    )
}

export default Layout